import { useAuthContext } from "./useAuthContext"

const useLogout = () =>{
    const { dispatch } = useAuthContext();
    const logout = ()=>{
        try {
            dispatch({ type: "LOGOUT" });
        } catch (error) {
            console.error('Logout failed:', error);
        }
    }
    return {logout};
}

export default useLogout;