import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { postRequest } from "../services/apiRequest";

const useSendOtp = () => {
  const [serverError, setServerError] = useState("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const phoneNumber = location.state && location.state.phone;
  const navigate = useNavigate();
  const schema = yup
    .object({
      phone: yup
        .string()
        .required()
        .matches(/^\d{8}$/, "Phone must be 8 digit number"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // onSubmit function to handle form submission
  const onSubmit = async (data) => {
    try {
      // Set loading state to true while waiting for the response
      setIsLoading(true);

      // Send a POST request to the backend API to get OTP
      const response = await postRequest("/auth/get-otp", {
        data: data,
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If successful, navigate to the OTP verification page with the phone number
        navigate("/auth/verify-otp", { state: { phone: data.phone } });
      } else {
        // If the response status is not 200, handle the error
        setIsLoading(false);
        setServerError(response.errors.phone);
      }

      // Indicate that the form submission was successful
      return true;
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    serverError,
    phoneNumber,
    isLoading,
  };
};
export default useSendOtp;
