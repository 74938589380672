// const Button = ({ children, variant, size, classNames }) => {
//   return <button className={'btn' + classNames}>{children}</button>;
// };
import './Button.scss';

const Button = ({
  children,
  className = '',
  size = '',
  varient = '',
  ...props
}) => (
  <button type='submit' className={`btn ${className} ${size} ${varient}`} {...props}>
    {children}
  </button>
);

export default Button;
