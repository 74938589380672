
const checkLeaves = (day, user) => {
    const dayDate = new Date(day);
    // Check if day is a weekend (Saturday or Sunday)
    if (dayDate.getDay() === 0 || dayDate.getDay() === 6) {
        return false; // No leaves on weekends
    }
    return user.leaves.some(
        (leave) =>
            new Date(day) >= new Date(leave.start_date) &&
            new Date(day) <= new Date(leave.end_date)
    );
};

export default checkLeaves;