import { useMemo } from "react";
import { useDataContext } from "../hooks/useDataContext.js";
import { differenceInDays } from "date-fns";

/**
 * Calculate the style for a task based on its start and end dates.
 * @param {Date} taskStartDate - The start date of the task.
 * @param {Date} taskEndDate - The end date of the task.
 * @returns {Object} - An object representing the CSS style for the task.
 */
function CalculateTaskStyle(taskStartDate, taskEndDate) {
  const { dates, columnWidth } = useDataContext();
  const yearStartDate = new Date(dates[0].date);
  const yearEndDate = new Date(dates[dates.length - 1].date);

  // Calculate the left and right values for positioning the task
  const taskLeftValue = columnWidth * (-(differenceInDays(yearStartDate, taskStartDate)));
  const taskRightValue = columnWidth * differenceInDays(yearEndDate, taskEndDate);

  // Use useMemo to memoize the CSS style object
  return useMemo(() => ({
    width: "auto",
    position: "absolute",
    left: `${taskLeftValue > -1 ? taskLeftValue + 3 : -1}px`,
    right: `${taskRightValue > -1 ? taskRightValue + 3 : 0}px`,
  }), [taskLeftValue, taskRightValue]);
}

export default CalculateTaskStyle;
