const calculateSubtaskTotal = (subtasksOnDay) => {
    return subtasksOnDay.reduce((subtaskTotal, subtask) => {
        // Remove leading zeros from time value
        const timeWithoutLeadingZeros = subtask.time.replace(/^0+/, "");
        // Split the time into hours and minutes
        const [hours, minutes] = timeWithoutLeadingZeros.split(":").map(Number);
        // Calculate the total time in hours with one decimal point
        const totalTimeInHours = parseFloat((hours + minutes / 60).toFixed(1));
        return subtaskTotal + totalTimeInHours;
    }, 0); // Convert the total to have one decimal point
};
export default calculateSubtaskTotal;