import React, { useState, useEffect } from 'react';
import { differenceInDays, format } from 'date-fns';

import './DeadLine.scss';
import bellIcon from '../../../assets/images/icons/bell.svg';

export default function DeadLine({ date }) {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const today = new Date();
    const deadlineDate = new Date(date);
    const daysRemaining = differenceInDays(deadlineDate, today);
    const totalDays = differenceInDays(deadlineDate, today) + 1; // Adding 1 to include the current day
    const calculatedPercentage =
      ((totalDays - daysRemaining) / totalDays) * 100;

    setPercentage(calculatedPercentage);
  }, [date]);

  const showDate = date ? format(date, 'dd LLL yyyy') : '';

  return (
    <div className="deadline">
      <div
        className="deadline__progress-bar"
        style={{ width: `${percentage}%` }}
      ></div>
      <span className="deadline__svg-icon">
        <img src={bellIcon} alt="" />
      </span>
      <p className="deadline__details">
        Deadline:
        <span> {showDate}</span>
      </p>
    </div>
  );
}
