const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const postRequest = async (endpoint, options = {}) => {
  try {
    const response = await fetch(BASE_URL + endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options.data),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export const postWithToken = async (endpoint, options = {}) => {
  try {
    let data = null;
    const response = await fetch(BASE_URL + endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${options.access_token}`,
      },
      body: JSON.stringify(options.data),
    });

    if (response.status === 401) {
      tokenExpire();
      throw new Error("Authentication failed: Unauthorized");
    } else if (response.ok) {
      data = await response.json();
    } else {
      throw new Error("Request failed with status:", response.status);
    }
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};
export const putWithToken = async (endpoint, options = {}) => {
  try {
    let data = null;
    const response = await fetch(BASE_URL + endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${options.access_token}`,
      },
      body: JSON.stringify(options.data),
    });

    if (response.status === 401) {
      tokenExpire();
      throw new Error("Authentication failed: Unauthorized");
    } else if (response.ok) {
      data = await response.json();
    } else {
      throw new Error("Request failed with status:", response.status);
    }
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export const postWithFile = async (endpoint, options = {}) => {
  try {
    let data = null;
    const response = await fetch(BASE_URL + endpoint, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${options.access_token}`,
      },
      body: options.data,
    });

    if (response.status === 401) {
      tokenExpire();
      throw new Error("Authentication failed: Unauthorized");
    } else if (response.ok) {
      data = await response.json();
    } else {
      throw new Error("Request failed with status:", response.status);
    }
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

const tokenExpire = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.href = "/auth/login";
};
