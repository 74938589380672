import React from "react";
import { useDataContext } from "../../../../hooks/useDataContext";
import { Dropdown } from "primereact/dropdown";
import { getYear } from "date-fns";
// Generate an array of years for the dropdown
const years = Array.from(
  { length: 10 },
  (_, index) => getYear(new Date()) - index
);
export default function YearChange({ handleYearChangeFor }) {
  const { filters, dispatch } = useDataContext();

  const handleYearChange = (type) => {
    return (event) => {
      const selectedYear = event.target.value;
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...filters,
          getSelectedYear: selectedYear,
          getSelectedMonth: null,
          getSelectedWeekNumbers: 0,
          selectedFilter: type,
        },
      });
    };
  };

  return (
    <div className="date-col">
      <label>Vaelg ar</label>
      <Dropdown
        value={filters.getSelectedYear}
        options={years}
        onChange={handleYearChange(handleYearChangeFor)}
        placeholder="Year"
      />
    </div>
  );
}
