import React, { useEffect, useState } from "react";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import checkCollisions from "../../../../utils/checkCollisions";
import TaskList from "../Task/TaskList.js";
import UserRow from "./UserRow.js";
/**
 * Component to render a row in the content section containing user information and their tasks.
 * @param {Object} props - The props object containing user data and task click handler.
 * @param {Object} props.user - The user object containing user data.
 * @param {Function} props.taskClick - The function to handle task click event.
 * @returns {JSX.Element} - Returns JSX for the content row.
 */
const ContentRow = ({ user }) => {
  const [taskWrapContainerHeight, setTaskWrapContainerHeight] = useState(0); // State for storing the height of task wrapper container
  const [users, setUsers] = useState([]); // State for storing user IDs to track if user data has been processed
  // Custom hook to handle side effect of updating user data
  useEffect(() => {
    // Check if user data exists and is not already processed
    if (user && !users.includes(user.userId)) {
      // Calculate total height of tasks container and update state
      const totalHeight = checkCollisions(user.tasks, user.userId, "assigned");
      setTaskWrapContainerHeight(totalHeight > 0 ? totalHeight + 80 : 0);
      // Update list of processed user IDs
      setUsers((prevUsers) => [...prevUsers, user.userId]);
    }
  }, [user, users]); // Dependencies for the effect

  // Render the content row with user information and tasks
  return (
    <div className="row" key={user.userId}>
      <div className="user-name">
        {user.firstname} <br /> {user.lastname}
      </div>
      <div
        className="year"
        style={{ minHeight: `${taskWrapContainerHeight}px`, height: "auto" }}
      >
        <UserRow user={user} /> {/* Render UserRow component */}
        <TaskList user={user} /> {/* Render TaskList component */}
      </div>
    </div>
  );
};

export default ContentRow;
