import { postWithToken } from "../services/apiRequest";
export const getDashboardData = async (token, filters) => {
  try {
    const req = {
      filters: filters,
    };
    const response = await postWithToken("/api/get-dashboard", {
      access_token: token,
      data: req,
    });
    return response;
    // Assuming postWithToken returns the actual data you need
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
  }
};
export const updateOrAddTasks = async (tasksDetails, token) => {
  try {
    const response = await postWithToken("/api/add-or-update-tasks", {
      access_token: token,
      data: tasksDetails,
    });
    return response;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
  }
};
