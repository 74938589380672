import React from "react";
import Task from "./Task";

/**
 * TaskList component renders a list of tasks for a given user or category.
 * @param {Object} props - The component props.
 * @param {Object} props.user - The user object containing tasks (optional).
 * @param {Object} props.category - The category object containing tasks (optional).
 * @returns {JSX.Element} TaskList component.
 */
const TaskList = ({ user, category }) => {
  // Determine the tasks based on user or category
  const tasks = user ? user.tasks : category ? category.tasks : [];

  return (
    <div className="task-wrap-container">
      <div className="task-wrap__relative">
        {tasks.map((task, taskIndex) => (
          <Task
            key={task.id}
            task={task}
            taskIndex={taskIndex}
            user={user}
            category={category}
          />
        ))}
      </div>
    </div>
  );
};

export default TaskList;
