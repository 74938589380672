import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import OtpInput from "react-otp-input";
import editIon from "../../assets/images/icons/edit-icon.svg";
import Button from "../../components/atom/button/Button";
import AuthLayout from "../../components/layout/AuthLayout";
import useOtpVerification from "../../hooks/useVerifyOtp";

const OtpVerification = () => {
  const {
    otp,
    error,
    handleOtpChange,
    phoneNumber,
    handleSubmit,
    handleEditPhoneNumber,
    resendCountdown,
    resendButton,
    isLoading,
    resendOtp,
  } = useOtpVerification();

  return (
    <AuthLayout>
      <div className="otp-verify">
        <div className="otp-verify__wrapper">
          <div className="login-form">
            <div className="Login-title">
              <h4>Verify Passcode</h4>
              <p>
                Enter the OTP send to your phone number “
                <span>{phoneNumber}</span>”
              </p>
              <p>
                Entered incorrect number?{" "}
                <a
                  href="#"
                  onClick={handleEditPhoneNumber}
                  className="edit-link"
                >
                  Change number
                  <img src={editIon} alt="edit icon" />
                </a>
              </p>
            </div>
            <label>Enter OTP</label>
            <form onSubmit={handleSubmit}>
              <div className="otp-input__wrapper">
                <OtpInput
                  value={otp}
                  shouldAutoFocus={true}
                  onChange={handleOtpChange}
                  numInputs={6}
                  inputType="tel"
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <p className="resend-otp">
                Did not receive OTP?{" "}
                {resendButton ? (
                  <span>
                    <span className="timeout resend-Link">
                      <a onClick={resendOtp}> Resend OTP </a>{" "}
                    </span>
                  </span>
                ) : (
                  <span>
                    Resend OTP in{" "}
                    <span className="timeout">{resendCountdown}</span> sec
                  </span>
                )}
              </p>
              {error && <p className="error-message">{error}</p>}
              <Button size={"large"} varient={"primary"} disabled={isLoading}>
                {isLoading ? (
                  <ProgressSpinner
                    style={{ width: "70px", height: "20px" }}
                    strokeWidth="10"
                    className="custom-spinner"
                    animationDuration="0.8s"
                  />
                ) : (
                  "verify & login"
                )}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default OtpVerification;
