import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import alertCircle from "../../assets/images/icons/alert-circle.svg";
import Button from "../../components/atom/button/Button";
import AuthLayout from "../../components/layout/AuthLayout";
import useSendOtp from "../../hooks/useSendOtp";

export default function App() {
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    serverError,
    phoneNumber,
    isLoading,
  } = useSendOtp();

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <div className="Login-title">
          <h4>Sign In</h4>
          <p>
            Please provide your registered phone number, We will sent you an one
            time passcode via SMS
          </p>
        </div>
        <div className="login-form">
          <label htmlFor="">Enter Phone Number</label>
          <div
            className={`p-inputgroup enter-phone-number ${
              errors.phone ? "error" : ""
            }`}
          >
            {errors.phone && (
              <img className="alert-icon" src={alertCircle} alt="alert icon" />
            )}

            <div className="country-code">
              <span className="p-inputgroup-addon">
                <i className="pi pi-phone"></i>
                +45{" "}
              </span>
            </div>

            <InputText
              {...register("phone")}
              placeholder="00000000"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              className={
                errors.phone || serverError
                  ? "p-ml-2 enter-number error"
                  : "p-ml-2 enter-number"
              }
              value={phoneNumber}
            />
          </div>
          {errors.phone && (
            <p className="error-message">{errors.phone.message}</p>
          )}

          {serverError ? (
            <>
              <p className="error-message">{serverError}</p>
              <p className="error-message__contact-admin">
                Keeps receiving the same error?
                <a href="#">Contact Admin</a>
              </p>
            </>
          ) : (
            errors.phone && (
              <p className="error-message__contact-admin">
                Keeps receiving the same error?
                <a href="#">Contact Admin</a>
              </p>
            )
          )}
          <Button size={"large"} varient={"primary"} disabled={isLoading}>
            {isLoading ? (
              <ProgressSpinner
                style={{ width: "70px", height: "20px" }}
                strokeWidth="10"
                className="custom-spinner"
                animationDuration="0.8s"
              />
            ) : (
              "Send one time passcode"
            )}
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
}
