/**
 * Checks if there is a collision between two DOM elements.
 * @param {Element} element1 - The first DOM element.
 * @param {Element} element2 - The second DOM element.
 * @returns {boolean} True if there is a collision, false otherwise.
 */
const isCollision = (element1, element2) => {
  const rect1 = element1.getBoundingClientRect();
  const rect2 = element2.getBoundingClientRect();

  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
};

/**
 * Handles the collision between two DOM elements by moving the second element below the first element.
 * @param {Element} element1 - The first DOM element.
 * @param {Element} element2 - The second DOM element.
 * @returns {number} The new top position of the second element.
 */
const CollisionHandle = (element1, element2) => {
  const newTop2 = element1.offsetTop + element1.offsetHeight;
  element2.style.top = `${newTop2}px`;
  return newTop2;
};

/**
 * Checks if there are any collisions among tasks within the same user's timeline.
 * @param {Array} tasks - An array of task elements.
 * @param {string} userId - The user ID.
 * @param {string} Idstring - The common prefix for task element IDs.
 * @returns {boolean} True if there is a collision, false otherwise.
 */
const hasCollision = (tasks, userId, Idstring) => {
  for (let i = 0; i < tasks.length - 1; i++) {
    const taskElement1 = document.getElementById(`${Idstring}${userId}-${i}`);
    if (taskElement1 && taskElement1.offsetWidth > 0) {
      for (let j = i + 1; j < tasks.length; j++) {
        const taskElement2 = document.getElementById(
          `${Idstring}${userId}-${j}`
        );
        if (taskElement2 && isCollision(taskElement1, taskElement2) && taskElement2.offsetWidth > 0) {
          return true;
        }
      }
    }
  }
  return false;
};

/**
 * Recursively checks for collisions among tasks within the same user's timeline and adjusts their positions to resolve collisions.
 * @param {Array} tasks - An array of task elements.
 * @param {string} userId - The user ID.
 * @param {string} Idstring - The common prefix for task element IDs.
 * @param {Array} heightList - An array to store the top positions of elements after collision handling.
 * @returns {number} The maximum height after collision handling.
 */
const checkCollisions = (tasks, userId, Idstring, heightList = []) => {
  let topArray = heightList;

  for (let i = 0; i < tasks.length - 1; i++) {
    const taskElement1 = document.getElementById(`${Idstring}${userId}-${i}`);

    if (taskElement1 && taskElement1.offsetWidth > 0) {
      for (let j = i + 1; j < tasks.length; j++) {
        const taskElement2 = document.getElementById(
          `${Idstring}${userId}-${j}`
        );
        if (taskElement2 && isCollision(taskElement1, taskElement2) && taskElement2.offsetWidth > 0) {
          const height = CollisionHandle(taskElement1, taskElement2);
          topArray.push(height);
        }
      }
    }
  }

  const collision = hasCollision(tasks, userId, Idstring);
  if (collision) {
    return checkCollisions(tasks, userId, Idstring, topArray)
  } else {
    const maxHeight = Math.max(...topArray, 0); // Ensure there's at least one element in the array
    return maxHeight;
  }
};

export default checkCollisions;
