import { useMemo } from "react";
import { useDataContext } from "../../../../hooks/useDataContext";
import { isToday } from "date-fns";
import checkLeaves from "../../../../utils/checkLeaves";
import { isWeekend } from "../../../../utils/calandarFns";
import hasTaskOnDay from "../../../../utils/hasTaskOnDay";
import getTotalTaskDuration from "../../../../utils/getTotalTaskDuration";
/**
 * Represents a cell displaying information for a specific day.
 * @param {object} props The component props.
 * @param {object} props.user The user data.
 * @param {object} props.day The day data.
 * @returns {JSX.Element} The rendered DayCell component.
 */
const DayCell = ({ user, day }) => {
    // get the column width from context
    const { columnWidth } = useDataContext()
    // if current date is leave
    const isLeave = useMemo(() => checkLeaves(day.date, user), [day, user]);
    // calculate total duration
    const duration = useMemo(() => {
        const tasks = user.tasks;
        const dayDate = new Date(day.date);
        const hasTask = hasTaskOnDay(dayDate, tasks);
        if (!hasTask) return null;
        return getTotalTaskDuration(tasks, dayDate);
    }, [user, day]);

    return (
        <div
            className={`col ${isWeekend(day.date) ? "weekend" : ""} ${isToday(new Date(day.date)) ? 'today' : ''}`}
            style={{ minWidth: `${columnWidth}px` }}>
            {isLeave && <div className="out-off-office">OUT</div>}
            {duration !== null && (
                <div className="task-duration">
                    <span>{duration.toFixed(1)} hrs</span>
                </div>
            )}
        </div>
    )
}

export default DayCell;
