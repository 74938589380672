import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postRequest } from "../services/apiRequest";
import { useAuthContext } from "./useAuthContext";

const UseVerifyOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const location = useLocation();
  const phoneNumber = location.state && location.state.phone;
  const [resendCountdown, setResendCountdown] = useState(30);
  const [resendButton, setResendButton] = useState(false);
  const [isLoading, setIsLoading] = useState(null);

  const { dispatch } = useAuthContext();

  useEffect(() => {
    localStorage.setItem("resendCountdown", resendCountdown);
    // Countdown logic
    let timer;
    if (resendCountdown > 0) {
      timer = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      // If countdown reaches 0, clear the interval
      setResendButton(true);
      clearInterval(timer);
    }

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, [resendCountdown]);

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };

  const handleEditPhoneNumber = () => {
    navigate("/auth/login", { state: { phone: phoneNumber } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitOtp();
  };
  /**
   * Submit OTP for verification.
   *
   * @returns {Promise<void>} - A Promise representing the asynchronous operation.
   */
  const submitOtp = async () => {
    try {
      setError(null);
      if (!otp) {
        return setError("OTP not be empty.");
      }
      // Set loading state to indicate the API request is in progress
      setIsLoading(true);

      // Prepare data for the API request
      const data = {
        otp: otp,
        phone: phoneNumber,
      };

      // Send a POST request to the backend to verify the OTP
      const response = await postRequest("/auth/verify-otp", {
        data: data,
      });

      // Check if the OTP verification was successful (status code 200)
      if (response.status === 200) {
        //  // update the auth context
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("token", response.access_token);
        dispatch({
          type: "LOGIN",
          payload: response.user,
          token: response.access_token,
        });
        if (response.user.type === 1) {
          navigate("/");
        } else {
          navigate("/");
        }
      } else {
        setError(response.errors?.otp);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle any errors that occur during the OTP verification process
      setIsLoading(false);

      // Set the error message based on the API response
      setError(error.response?.data?.errors?.otp);
    }
  };

  /**
   * Resend OTP to the user's phone number.
   *
   * @returns {Promise<void>} - A Promise representing the asynchronous operation.
   */
  const resendOtp = async () => {
    try {
      setOtp("");
      // Set loading state to indicate the API request is in progress
      setIsLoading(true);

      // Prepare data for the API request
      const data = {
        phone: phoneNumber,
      };

      // Send a POST request to the backend to resend OTP
      const response = await postRequest("/auth/get-otp", {
        data: data,
      });

      // Check if the OTP resend was successful (status code 200)
      if (response.status === 200) {
        // Set the countdown to 5 seconds and disable the resend button
        setResendCountdown(30);
        setResendButton(false);
      }

      // Reset loading state as the API request is complete
      setIsLoading(false);
    } catch (error) {
      // Handle any errors that occur during the OTP resend process
      console.error("Error:", error);

      // Reset loading state in case of an error
      setIsLoading(false);
    }
  };

  return {
    otp,
    error,
    handleOtpChange,
    handleSubmit,
    phoneNumber,
    handleEditPhoneNumber,
    resendButton,
    resendCountdown,
    resendOtp,
    isLoading,
  };
};
export default UseVerifyOtp;
