import React from "react";

export default function PartnerTaskHeaderCommon({
  taskDetails,
  phone = false,
}) {
  const checkCountrySide = (code) => {
    switch (code) {
      case 1:
        return "Sjælland";
      case 2:
        return "Fyn";
      case 3:
        return "Jylland";
      default:
        return "";
    }
  };
  return (
    <>
      <div
        className={`partner-task-priority ${
          taskDetails?.task?.type === 1 ? "Maleropgave" : ""
        }`}
      >
        <span>
          {taskDetails?.task?.type == 1 ? "Maleropgave" : "Maler Reklamation"}
        </span>
      </div>
      <div className="partner-task-details_title">
        <div className="title-icon">
          <div className="icon-brush">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
            >
              <rect
                x="0.816406"
                width="25"
                height="25"
                rx="12.5"
                fill="#ABDEEE"
                fillOpacity="0.34"
              />
              <path
                d="M8.94102 5.5C8.80178 5.5 8.66824 5.55531 8.56978 5.65377C8.47133 5.75223 8.41602 5.88576 8.41602 6.025V14.075C8.41602 14.4927 8.58195 14.8933 8.87732 15.1887C9.17269 15.4841 9.5733 15.65 9.99102 15.65H11.566V17.75C11.566 18.2141 11.7504 18.6592 12.0786 18.9874C12.4068 19.3156 12.8519 19.5 13.316 19.5C13.7801 19.5 14.2253 19.3156 14.5535 18.9874C14.8816 18.6592 15.066 18.2141 15.066 17.75V15.65H16.641C16.8478 15.65 17.0527 15.6093 17.2437 15.5301C17.4348 15.451 17.6085 15.3349 17.7547 15.1887C17.901 15.0424 18.017 14.8688 18.0961 14.6777C18.1753 14.4866 18.216 14.2818 18.216 14.075V6.025C18.216 5.88576 18.1607 5.75223 18.0622 5.65377C17.9638 5.55531 17.8303 5.5 17.691 5.5H8.94102ZM9.46602 11.8V6.55H13.666V7.7764C13.666 7.91564 13.7213 8.04917 13.8198 8.14763C13.9182 8.24609 14.0518 8.3014 14.191 8.3014C14.3303 8.3014 14.4638 8.24609 14.5622 8.14763C14.6607 8.04917 14.716 7.91564 14.716 7.7764V6.55H15.416V8.4757C15.416 8.61494 15.4713 8.74847 15.5698 8.84693C15.6682 8.94539 15.8018 9.0007 15.941 9.0007C16.0803 9.0007 16.2138 8.94539 16.3122 8.84693C16.4107 8.74847 16.466 8.61494 16.466 8.4757V6.55H17.166V11.8H9.46602ZM9.46602 14.075V12.85H17.166V14.075C17.166 14.2142 17.1107 14.3478 17.0122 14.4462C16.9138 14.5447 16.7803 14.6 16.641 14.6H14.541C14.4018 14.6 14.2682 14.6553 14.1698 14.7538C14.0713 14.8522 14.016 14.9858 14.016 15.125V17.75C14.016 17.9357 13.9423 18.1137 13.811 18.245C13.6797 18.3763 13.5017 18.45 13.316 18.45C13.1304 18.45 12.9523 18.3763 12.821 18.245C12.6898 18.1137 12.616 17.9357 12.616 17.75V15.125C12.616 14.9858 12.5607 14.8522 12.4622 14.7538C12.3638 14.6553 12.2303 14.6 12.091 14.6H9.99102C9.85178 14.6 9.71824 14.5447 9.61978 14.4462C9.52133 14.3478 9.46602 14.2142 9.46602 14.075Z"
                fill="#219BC2"
              />
            </svg>
          </div>
        </div>
        <h3 className="title">
          {taskDetails &&
            taskDetails?.task?.customer_first_name +
              taskDetails?.task?.customer_last_name +
              (taskDetails?.task?.countryside
                ? ` - ${checkCountrySide(taskDetails?.task?.countryside)}`
                : "")}
        </h3>
      </div>
      <div className="address-field">
        {taskDetails?.task?.customer_address && (
          <p
            className="adress"
            dangerouslySetInnerHTML={{
              __html: taskDetails?.task?.customer_address,
            }}
          ></p>
        )}
        {phone && taskDetails?.task?.customer_phone_number && (
          <p className="phone">{taskDetails?.task?.customer_phone_number}</p>
        )}
      </div>
    </>
  );
}
