import { useEffect, useState } from "react";
import { useDataContext } from "./useDataContext";
import { subDays, addDays } from "date-fns";

export const usePartnerView = () => {
  const { filters, dispatch, popUpStatus } = useDataContext();
  const [showContent, setShowContent] = useState(false);
  const [selectDate, setSelectedDate] = useState(null);

  const openModal = (task) => {
    dispatch({
      type: "POP_UP_STATUS",
      payload: true,
    });
    dispatch({
      type: "SINGLE_TASK",
      payload: task,
    });
  };

  popUpStatus
    ? (document.body.style.overflowY = "hidden")
    : (document.body.style.overflowY = "auto");

  useEffect(() => {
    setShowContent(true);
  }, []);

  const closeModal = () => {
    dispatch({
      type: "POP_UP_STATUS",
      payload: false,
    });
  };

  /**
   * Handle change in the selected date option.
   * @param {string} selectedOption
   */
  const handleDateChange = (selectedOption) => {
    let date = "";
    let filter = "";
    switch (selectedOption) {
      case "previous":
        date = subDays(new Date(), 30);

        break;
      case "today":
        date = new Date();
        break;
      case "next":
        date = addDays(new Date(), 30);
        break;
      case "":
        date = new Date();
        break;
      default:
    }
    dispatch({
      type: "POP_UP_STATUS",
      payload: false,
    });
    // Convert the date to UTC and format it to ISO string
    const originalDate = new Date(date);
    const utcDate = new Date(
      Date.UTC(
        originalDate.getFullYear(),
        originalDate.getMonth(),
        originalDate.getDate(),
        originalDate.getHours(),
        originalDate.getMinutes(),
        originalDate.getSeconds()
      )
    );
    // Now, format the UTC date to ISO string
    const formattedDate = utcDate.toISOString().split("T")[0];
    setSelectedDate(date);

    if (date !== "") {
      filter = selectedOption;
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, ["date"]: formattedDate, ["filter"]: filter },
    });
    dispatch({
      type: "POP_UP_STATUS",
      payload: false,
    });
  };

  return {
    openModal,
    closeModal,
    handleDateChange,
    selectDate,
    showContent,
  };
};
