import * as dateFns from "date-fns";
import {
  differenceInDays,
  format,
  getYear,
  subWeeks,
  startOfISOWeek,
} from "date-fns";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDataContext } from "../../../hooks/useDataContext";
import ProfileDropdown from "../../compound/ProfileDropdown";
import Arrow from "../../../assets/images/down-arrow.svg";
import "primeicons/primeicons.css";
import NextBtn from "./Button/NextBtn";
import PrevBtn from "./Button/PrevBtn";
import AdvancedFilterOptions from './AdvancedFilter/Index';
import "../DashboardFilters/dashboardFilters.scss";


const DashboardFilters = () => {
  const { filterOptions, filters, dispatch } = useDataContext();
  const { startDate, endDate } = filters;
  const [filterActive, setFilterActive] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const currentDate = new Date();
  const daysDifference = useMemo(
    () => differenceInDays(endDate, startDate) + 1,
    [startDate, endDate]
  );
  const dropdownRef = useRef(null);
  const yearOptions = [];
  for (let i = getYear(currentDate) - 10; i <= getYear(currentDate) + 10; i++) {
    yearOptions.push({ label: `${i}`, value: i });
  }
  // toggle  advance filter
  const toggleFilter = () => {
    setFilterActive(!filterActive);
  };
  // filter actions
  const handleChange = (filterType) => (selectedOption) => {
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, [filterType]: selectedOption.value },
    });
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const clearAdvancedFilter = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...filters,
        startDate: startOfISOWeek(subWeeks(new Date(), 5)),
        endDate: dateFns.addDays(new Date(), 90),
        selectedFilter: null,
        getSelectedMonth: null,
        getSelectedYear: null,
        getSelectedWeekNumbers: null,
      },
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element is within the PrimeReact dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest(".p-dropdown-items-wrapper") &&
        !event.target.closest(".date-filter-dropdown-arrow.active")
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-header">
      <section className="filter">
        <div className="container">
          {/* General filters */}
          <div className="filter-wrapper">
            <div
              className="filter-label"
              onClick={() => {
                if (window.innerWidth < 1200) {
                  toggleFilter();
                }
              }}>
              <i className="pi pi-filter" style={{ fontSize: "1rem" }}></i>
              <p>Filter</p>
            </div>
            <div
              className={`filter-col__wrapper ${filterActive ? "active" : ""}`}>
              <div className="filter-col">
                <Dropdown
                  showClear
                  value={filters.type}
                  options={filterOptions?.types}
                  optionLabel="name"
                  placeholder="Type"
                  className="w-full "
                  onChange={handleChange("type")}
                />
              </div>
              <div className="filter-col">
                <Dropdown
                  showClear
                  value={filters.service_managers}
                  options={filterOptions?.service_managers}
                  optionLabel="name"
                  placeholder="Service Manager"
                  className="w-full "
                  onChange={handleChange("service_managers")}
                />
              </div>
              <div className="filter-col">
                <Dropdown
                  showClear
                  value={filters.partner}
                  options={filterOptions?.partners}
                  optionLabel="name"
                  placeholder="Partner"
                  className="w-full"
                  onChange={handleChange("partner")}
                />
              </div>
              <div className="filter-col">
                <Dropdown
                  showClear
                  value={filters.lansdel}
                  options={filterOptions?.lansdel}
                  optionLabel="name"
                  placeholder="Lansdel"
                  className="w-full "
                  onChange={handleChange("lansdel")}
                />
              </div>
            </div>
          </div>

          {/* data filter dropdown */}
          <div className="date-filter">
            {/* Date switcher */}
            <div className="date-filter-head">
              <div className="date-filter-arrows">
                <PrevBtn daysDifference={daysDifference} />
                <NextBtn daysDifference={daysDifference} />
              </div>
              <div className="show-date" onClick={() => clearAdvancedFilter()}>
                {/* Display start and end dates */}
                {startDate && endDate && (
                  <div className="date-range-preview">
                    <p>{format(new Date(), "LLL dd yyyy")}</p>
                  </div>
                )}
              </div>
              {/* date range filter -dropdown */}
              <div
                className={`date-filter-dropdown-arrow ${
                  !dropdownVisible ? "inactive" : "active"
                }`}
                onClick={toggleDropdown}>
                <img src={Arrow} alt="arrow" />
              </div>

              {/* Dropdown list */}
              {dropdownVisible && (
                <div className="date-filter-dropdown" ref={dropdownRef}>
                  <h4>Vis overblik for</h4>
                  <AdvancedFilterOptions />
                </div>
              )}
            </div>
          </div>
          <div className="profile">
            <ProfileDropdown />
          </div>
        </div>
      </section>
    </div>
  );
};

export default DashboardFilters;
