import { Calendar } from "primereact/calendar";
import React from "react";
import logoImage from "../../../assets/images/mobile-logo.svg";
import ProfileDropdown from "../../compound/ProfileDropdown";
import Button from "../../atom/button/Button";

const mobileStyle = {
  marginLeft: "30px", // Adjust the spacing as needed
};

export default function PartnerHeader({ options, onChange }) {
  // let setActiveClass = options == ''?
  return (
    <div className="partner-view__header">
      <div className="header-left">
        <a href="#" className="logo">
          <img src={logoImage} alt="logo" />
        </a>
        <div className="partner-view__header_date-filter">
          <span

            className={`prev-30-days ${options == 'previous' ? 'active' : ''}`}
            onClick={() => {
              onChange("previous");
            }}
          >
            Sidste 30 dage
          </span>
          <span
            className={`today ${options == 'today' ? 'active' : ''}`}
            onClick={() => {
              onChange("today");
            }}
          >
            i dag
          </span>
          <span

            className={`next-30-days  ${options == 'next' ? 'active' : ''}`}
            onClick={() => {
              onChange("next");
            }}
          >
            Naeste 30 dage
          </span>
          <span
            className="clear"
            onClick={() => {
              onChange("");
            }}
          >
            Clear
          </span>
        </div>
      </div>

      <ProfileDropdown />
    </div>
  );
}
