import { createContext, useEffect, useMemo, useReducer, useState } from "react";
import { getDashboardData } from "../utils/commonFunctions";
import { useAuthContext } from "../hooks/useAuthContext";
import { startOfISOWeek, subWeeks, addDays, differenceInDays } from "date-fns";
import { dataReducer } from "./dataReducer";

export const DataContext = createContext();

const partners = Array.from({ length: 50 }, (_, index) => ({
  user_id: index + 1,
  item_id: 50003 + index,
  name: `Partner ${index + 1}`,
  email: `Partner${index + 1}@example.com`,
  phone: `555-${Math.floor(Math.random() * 9000) + 1000}`,
  type: index % 2 === 0 ? "regular" : "admin",
}));

const types = [
  { id: 1, name: "Maleropgave" },
  { id: 2, name: "Maler Reklamationss" },
];

const landsdel = [
  { id: 1, value: "Sjælland" },
  { id: 2, value: "Fyn" },
  { id: 3, value: "Jylland" },
];

const managers = Array.from({ length: 10 }, (_, index) => ({
  user_id: index + 1,
  item_id: 100003 + index,
  name: `Manager ${index + 1}`,
  email: `Manager${index + 1}@example.com`,
  phone: `555-${Math.floor(Math.random() * 9000) + 1000}`,
  type: index % 2 === 0 ? "regular" : "admin",
}));

const Filters = {
  types: types,
  managers: managers,
  partners: partners,
  landsdel: landsdel,
};

const getDayName = (date) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return daysOfWeek[date.getDay()];
};

const getMonthName = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[date.getMonth()];
};

const today = new Date();
// Format the date as "yyyy-mm-dd"
const date = today.toISOString().split("T")[0];

export const DataContextProvider = ({ children }) => {
  const { access_token } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(dataReducer, {
    filterOptions: Filters,
    partners: partners,
    data: null,
    unassignedData: null,
    taskDetails: null,
    leaves: null,
    filters: {
      type: null,
      service_managers: null,
      partner: null,
      lansdel: null,
      date: date,
      startDate: startOfISOWeek(subWeeks(new Date(), 5)),
      endDate: addDays(new Date(), 90),
      filter: "",
      getSelectedYear: null,
      getSelectedMonth: null,
      getSelectedWeekNumbers: 0,
      selectedFilter: null,
    },
    popUpStatus: false,
  });
  const { startDate, endDate } = state.filters;

  useEffect(() => {
    fetchData();
  }, [state.filters]);

  const fetchData = async () => {
    const { filters } = state;
    if (
      filters.selectedFilter === "year_week_number" &&
      (!filters.getSelectedWeekNumbers || !filters.getSelectedYear)
    )
      return;
    if (
      filters.selectedFilter === "year_month_number" &&
      (!filters.getSelectedMonth || !filters.getSelectedYear)
    )
      return;

    setLoading(true);
    try {
      let response = await getDashboardData(access_token, filters);
      setData(response);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  const setData = (response) => {
    dispatch({
      type: "DATA",
      payload: response?.data?.tasks?.assigned,
    });
    dispatch({
      type: "SET_FILTER_OPTIONS",
      payload: response?.data?.filters,
    });
    dispatch({
      type: "UNASSIGNED_TASK",
      payload: response?.data?.tasks?.unassigned,
    });
    dispatch({
      type: "LEAVED_PARTNERS",
      payload: response?.data?.leaved_partners,
    });
  };

  // generate dates array
  const dates = useMemo(() => {
    let dates = [];
    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const formattedDate = {
        date: date.toDateString(),
        dayName: getDayName(date),
        dayNumber: date.getDate(),
        monthName: getMonthName(date),
      };

      dates.push(formattedDate);
    }
    return dates;
  }, [startDate, endDate]);

  // calculate column width
  const columnWidth = useMemo(() => {
    const minWidth = 50;
    const days = differenceInDays(endDate, startDate);
    const mainPanel = document.querySelector(".main-panel");
    let viewWidth = 0;
    if (mainPanel) {
      viewWidth = mainPanel.clientWidth - 116;
    } else {
      viewWidth = window.innerWidth - 116;
    }

    const calendarWidth = viewWidth;

    // const colWidth = Math.floor(calendarWidth / (days + 1));
    const colWidth = calendarWidth / (days + 1);
    return colWidth < minWidth ? minWidth : colWidth;
  }, [startDate, endDate]);

  return (
    <DataContext.Provider
      value={{ ...state, dates, columnWidth, dispatch, loading }}
    >
      {children}
    </DataContext.Provider>
  );
};
