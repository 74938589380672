import React from 'react';
import '../TableHeader/TableHeader.scss';
import { getISOWeek, isToday } from "date-fns";
import { useDataContext } from "../../../../hooks/useDataContext";
/**
 * TableHeader component displays the header row of a table, showing weeks and dates.
 * It utilizes data from the DataContext using the useDataContext hook.
 */
const TableHeader = () => {
  const { dates, columnWidth } = useDataContext();

  // Get unique week numbers from the provided dates
  const uniqueWeeks = [
    ...new Set(dates.map((day) => getISOWeek(new Date(day.date)))),
  ];

  return (
    <div className="header-row-content">
      <div className="week-row">
        {uniqueWeeks.map((weekNumber, weekIndex) => (
          <div key={weekIndex} className={`week-number`}>
            <span className={`week-text ${weekNumber}`}>
              Week {weekNumber}
            </span>
            {dates &&
              dates.map((day, index) => {
                const result = getISOWeek(new Date(day.date));
                if (result === weekNumber) {
                  return (
                    <div
                      key={index}
                      className={`day`}
                      style={{
                        width: `${columnWidth}px`,
                        height: `${20}px`,
                      }}
                    ></div>
                  );
                }
                return null; // Skip rendering if not part of the current week
              })}
          </div>
        ))}
      </div>
      <div className="year">
        {dates &&
          dates.map((day, index) => (
            <div
              key={index}
              className={`col ${isToday(new Date(day.date)) ? 'today' : ''}`}
              style={{ minWidth: `${columnWidth}px` }}
            >
              <span className="day">{day.dayName}</span>
              <span className="date">
                {day.monthName} {day.dayNumber}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TableHeader;
