import { format } from "date-fns";
import { React, lazy } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/styles/partnerView/PartnerView.scss";
import DeadLine from "../../components/atom/DeadLine/DeadLine";
import Loading from "../../components/compound/Loader";
import PartnerHeader from "../../components/layout/PartnerView/PartnerHeader";
import { useDataContext } from "../../hooks/useDataContext";
import { usePartnerView } from "../../hooks/usePartnerView";
import PartnerTaskHeaderCommon from "../../components/layout/PartnerView/PartnerTaskHeaderCommon";

const PartnerDetailedView = lazy(() =>
  import("../../components/layout/PartnerView/Index")
);

export default function PartnerView() {
  const { openModal, closeModal, handleDateChange } = usePartnerView();
  const { data, loading, filters } = useDataContext();

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="partner-view">
        <ToastContainer />
        <div className="container">
          <PartnerHeader
            options={filters?.filter}
            onChange={handleDateChange}
            placeholderText="Select a date"
          />
          <div className="partner-view__partner-task">
            {data && Object.keys(data).length > 0 ? (
              Object.entries(data).map(([period, tasks]) => (
                <div className="partner-task-list" key={period}>
                  <div className="partner-task-list__heading">
                    <h2>{period}</h2>
                    <p>
                      {" "}
                      {tasks &&
                        tasks.length > 0 &&
                        tasks
                          .reduce((total, task) => {
                            const timeWithoutLeadingZeros = task.time.replace(
                              /^0+/,
                              ""
                            );
                            const [hours, minutes] = timeWithoutLeadingZeros
                              .split(":")
                              .map(Number);
                            const totalTimeInHours = hours + minutes / 60;
                            return total + totalTimeInHours;
                          }, 0)
                          .toFixed(1)}{" "}
                      Hours allocated
                    </p>
                  </div>
                  {tasks &&
                    tasks.length > 0 &&
                    tasks.map((task, index) => (
                      <div
                        className={`partner-task`}
                        key={index}
                        onClick={() => openModal(task)}
                        style={{ borderColor: task?.task?.country_side_color }}
                      >
                        <div className="partner-task-priority">
                          {task?.priority && <span>{task?.priority}</span>}
                        </div>
                        <div className="partner-task-details">
                          <PartnerTaskHeaderCommon taskDetails={task} />
                          <div className="partner-task-deadline">
                            <DeadLine
                              date={format(
                                new Date(task && task?.task?.end_date),
                                "dd MMMM yyyy"
                              )}
                            />
                            <div className="partner-task-duration"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <div className="no-data">
                <p>No data available. Please check again later.</p>
              </div>
            )}
          </div>
          <PartnerDetailedView onClose={closeModal} />
        </div>
      </div>
    );
  }
}
