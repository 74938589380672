
/**
 * Check if the given day us weekend
 * @param {*} day 
 * @returns boolean
 */
export const isWeekend = (day) => {
    const date = new Date(day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 is Sunday, 6 is Saturday
};
