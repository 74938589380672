import { useDataContext } from "../../../../hooks/useDataContext";
import { isWeekend, isToday } from "date-fns";

/**
 * Component representing a row of dates with additional styling for weekends and today's date.
 * @returns {JSX.Element} The CategoryRow component.
 */
export default function CategoryRow() {
    // Accessing data context using custom hook
    const { dates, columnWidth } = useDataContext();

    return (
        // Mapping over each date to render a column
        dates.map((day, index) => {
            return (
                // Rendering a div for each date
                <div
                    key={index}
                    // Adding classes based on whether it's a weekend or today
                    className={`col ${isWeekend(day.date) ? "weekend" : ""} ${isToday(new Date(day.date)) ? "today" : ""}`}
                    // Setting minimum width of column based on provided columnWidth
                    style={{ minWidth: `${columnWidth}px` }}
                ></div>
            );
        })
    )
}
