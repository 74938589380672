import calculateSubtaskTotal from "./calculateSubtaskTotal";
/**
 * Calculates the total duration of tasks for the specified day.
 * @param {Array} tasks An array of tasks.
 * @param {Date} dayDate The date for which to calculate the total duration of tasks.
 * @returns {number} The total duration of tasks for the specified day.
 */
const getTotalTaskDuration = (tasks, dayDate) => {
  return tasks.reduce((totalDuration, task) => {
    const taskStartDate = new Date(task.startDate);
    const taskEndDate = new Date(task.endDate);
    if (
      (taskStartDate <= dayDate && taskEndDate >= dayDate) ||
      (task.subtasks && task.subtasks.some(
        (subtask) => new Date(subtask.date) <= dayDate && new Date(subtask.date) >= dayDate && subtask.time
      ))
    ) {
      const subtasks = task.subtasks || [];
      const subtasksOnDay = subtasks.filter((subtask) => {
        const subtaskStartDate = new Date(subtask.date);
        const subtaskEndDate = new Date(subtask.date);
        return subtaskStartDate <= dayDate && subtaskEndDate >= dayDate && subtask.time;
      });
      return totalDuration + calculateSubtaskTotal(subtasksOnDay);
    }
    return totalDuration;
  }, 0);
}
export default getTotalTaskDuration;