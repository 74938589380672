export const dataReducer = (state, action) => {
  switch (action.type) {
    case "SET_FILTER_OPTIONS":
      return {
        ...state,
        filterOptions: action.payload,
      };
    case "SET_FILTERS": {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case "PARTNERS":
      return {
        ...state,
        partners: action.payload,
      };
    case "DATA":
      return {
        ...state,
        data: action.payload,
      };

    case "UNASSIGNED_TASK":
      return {
        ...state,
        unassignedData: action.payload,
      };
    case "SINGLE_TASK":
      return {
        ...state,
        taskDetails: action.payload,
      };
    case "LEAVED_PARTNERS":
      return {
        ...state,
        leaves: action.payload,
      };
    case "POP_UP_STATUS":
      return {
        ...state,
        popUpStatus: action.payload,
      };

    default:
  }
};
