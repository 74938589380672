import React from "react";
import CalculateTaskStyle from "../../../../utils/CalculateTaskStyle";
import useMainTaskView from "../../../../hooks/useMainTaskView";

/**
 * Component representing a single task item.
 * @param  {Object} props - The component props.
 * @param  {Object} props.task - The task object containing task details.
 * @param   {Function} props.taskClick - Function to handle task click event.
 * @param   {number} props.taskIndex - The index of the task.
 * @param   {Object} props.category - The category object associated with the task (optional).
 * @param   {Object} props.user - The user object associated with the task.
 * @returns {JSX.Element} JSX representation of the Task component.
 */
const Task = ({ task, taskIndex, category, user }) => {
  const { setSingleTaskData } = useMainTaskView();
  const { openMainTask } = useMainTaskView();
  // Calculate task style based on start and end dates
  const taskStyle = CalculateTaskStyle(task.startDate, task.endDate);

  /**
   * Set task data and trigger task click event.
   * @param {Object} task - The task object.
   */
  const setTaskData = (task) => {
    setSingleTaskData(task, user);
    openMainTask();
  };

  // Generate class name for task wrapper
  const taskWrapClass = category
    ? `unassigned${category.categoryId}-${taskIndex}`
    : `assigned${user.userId}-${taskIndex}`;

  return (
    <div
      className={`task-wrap ${task.shared ? "shared-task" : ""}`}
      style={taskStyle}
      id={`${taskWrapClass}`}
    >
      <div
        className={`task`}
        onClick={() => setTaskData(task)}
        title={task.taskName}
        style={{ backgroundColor: task.country_side_color }}
      >
        <span>
          {task.taskName} {category ? "" : `- ${task.countryside}`}
        </span>
      </div>
    </div>
  );
};

export default Task;
