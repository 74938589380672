import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../../hooks/useDataContext";
import checkCollisions from "../../../../utils/checkCollisions.js";
import CategoryRow from "./CategoryRow.js";
import TaskList from "../Task/TaskList.js";

/**
 * ContentRowUnassigned component displays a row for an unassigned category with its associated tasks.
 * @param {Object} props - The component props.
 * @param {Object} props.category - The category object containing category details and tasks.
 * @param {function} props.taskClick - The function to handle task click events.
 * @returns {JSX.Element} ContentRowUnassigned component.
 */
const ContentRowUnassigned = ({ category }) => {
  // Custom hook to access filter options from context
  const { filterOptions } = useDataContext();

  // State to store the height of the task wrap container
  const [taskWrapContainerHeight, setTaskWrapContainerHeight] = useState(0);
  /**
   * Function to get the name of the category by its ID.
   * @param {string} categoryId - The ID of the category.
   * @returns {string} The name of the category.
   */
  const getCategory = (categoryId) => {
    return filterOptions.lansdel.find((element) => element.id === categoryId)
      ?.name;
  };

  /**
   * Function to get the color of the category by its ID.
   * @param {string} categoryId - The ID of the category.
   * @returns {string} The color of the category.
   */
  const getCategoryColor = (categoryId) => {
    return filterOptions.lansdel.find((element) => element.id === categoryId)
      ?.color;
  };

  // Effect hook to update the task wrap container height when the category changes
  useEffect(() => {
    if (category) {
      const totalHeight = checkCollisions(
        category.tasks,
        category.categoryId,
        "unassigned"
      );
      setTaskWrapContainerHeight(totalHeight > 0 ? totalHeight + 70 : 0);
    }
  }, [category]);

  return (
    <div className="row" key={category.categoryId}>
      <div
        className={`category-name`}
        style={{ backgroundColor: getCategoryColor(category.categoryId) }}
      >
        <span className="rotated-text">{getCategory(category.categoryId)}</span>
      </div>
      <div
        className="year"
        style={{ minHeight: "70px", height: taskWrapContainerHeight + "px" }}
      >
        {/* Render CategoryRow component */}
        <CategoryRow />
        {/* Render TaskList component with category and taskClick props */}
        <TaskList category={category} />
      </div>
    </div>
  );
};

export default ContentRowUnassigned;
