/**
 * Checks if there are tasks scheduled on the specified day.
 * @param {Date} dayDate The date to check for tasks.
 * @param {Array} tasks An array of tasks.
 * @returns {boolean} True if there are tasks scheduled on the specified day, false otherwise.
 */
const hasTaskOnDay = (dayDate, tasks) => {
    return tasks.some(
        (task) => dayDate >= new Date(task.startDate) && dayDate <= new Date(task.endDate)
    );
};
export default hasTaskOnDay;