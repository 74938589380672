import React from 'react';
import { useDataContext } from "../../../../hooks/useDataContext";
import DayCell from "./DayCell";

/**
 * Renders a row representing a user with cells for each day.
 * @param {Object} props - The props for the UserRow component.
 * @param {Object} props.user - The user object containing information to be displayed.
 * @returns {JSX.Element} A row representing a user.
 */
const UserRow = ({ user }) => {
    // Access the dates from the data context
    const { dates } = useDataContext();
    // Render a cell for each day
    return (
        <>
            {dates.map((day, index) => {
                return (
                    <DayCell key={index} day={day} user={user} />
                );
            })}
        </>
    );
}



export default UserRow;
