import { addDays } from "date-fns";
import { useDataContext } from "../../../../hooks/useDataContext";
import Arrow from "../../../../assets/images/down-arrow.svg";

const NextBtn = ({ daysDifference }) => {
  const { filters, dispatch } = useDataContext();
  const { startDate, endDate } = filters;

  const handleNext = () => {
    const nextStartDate = addDays(startDate, daysDifference);
    const nextEndDate = addDays(endDate, daysDifference);
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...filters,
        startDate: new Date(nextStartDate),
        endDate: new Date(nextEndDate),
      },
    });
  };
  return (
    <div className="next-arrow" onClick={handleNext}>
      <img src={Arrow} alt="arrow" />
    </div>
  );
};

export default NextBtn;
