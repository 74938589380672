import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/compound/Loader";
import DashboardFilters from "../../components/layout/DashboardFilters/Index";
import DashboardTable from "../../components/layout/DashboardTable/Index";
import { useDataContext } from "../../hooks/useDataContext";

const Dashboard = () => {
  const { loading } = useDataContext();

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div>
        <>
          <ToastContainer />
          <DashboardFilters />
          <DashboardTable />
        </>
      </div>
    );
  }
};

export default Dashboard;
