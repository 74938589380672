import { React, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "../pages/auth/login";
import OTP from "../pages/auth/verifyOtp";
import Dashboard from "../pages/dashboard/Index";
import PartnerView from "../pages/PartnerView/PartnerView";
import { useAuthContext } from "../hooks/useAuthContext";
import NotFound from "../pages/NotFound";

import { DataContextProvider } from "../context/dataContext";
import Loading from "../components/compound/Loader";

const AppRoutes = () => {
  const { user } = useAuthContext();

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route
            path="/auth/login"
            element={!user ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/auth/verify-otp"
            element={!user ? <OTP /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={
              user ? (
                user.type === 0 ? (
                  <DataContextProvider>
                    <PartnerView />
                  </DataContextProvider>
                ) : user.type === 1 ? (
                  <DataContextProvider>
                    <Dashboard />
                  </DataContextProvider>
                ) : (
                  <Navigate to="/auth/login" />
                )
              ) : (
                <Navigate to="/auth/login" />
              )
            }
          />
          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};
export default AppRoutes;
