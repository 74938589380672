import { differenceInDays } from "date-fns";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import { Resizable } from "re-resizable";
import React, { useEffect, useRef, useState, lazy } from "react";
import { useDataContext } from "../../../hooks/useDataContext.js";
import Loading from "../../compound/Loader.js";
import ContentRowUnassigned from "./ContentRowUnassigned/Index.js";
import "./DashboardTable.scss";
import TableHeader from "./TableHeader/Index.js";
import "./TableHeader/TableHeader.scss";
import ContentRow from "./contentRow/Index.js";

const MainTaskView = lazy(() => import("./MainTaskView/Index.js"));

const DashboardTable = () => {
  const [showContent, setShowContent] = useState(false);
  const { data, unassignedData, columnWidth, filters } =
    useDataContext();
  const [maxHeight, setMaxHeight] = useState();
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const innerContentRef = useRef(null);
  const initialHeight =
    localStorage.getItem("tableRowHeight") || window.innerHeight - 200;
  const scrollToToday = () => {
    const parentDiv = div1Ref.current;
    const childDiv = document.querySelector(".today");
    const getDayDiff = differenceInDays(new Date(), filters.startDate);
    if (childDiv) {
      parentDiv.scrollLeft += getDayDiff * columnWidth;
    }
  };

  useEffect(() => {
    setShowContent(true);
  }, []);

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    if (div2Ref.current && div1Ref.current) {
      div1Ref.current.scrollLeft = scrollLeft;
      div2Ref.current.scrollLeft = scrollLeft;
    }
  };

  const handleTableHeight = (event, direction, ref, d) => {
    // access the new height with ref.offsetHeight
    const newHeight = ref?.offsetHeight;
    localStorage.setItem("tableRowHeight", newHeight);
  };

  useEffect(() => {
    if (innerContentRef?.current) {
      let windowHieght = window.innerHeight - 100;
      setTimeout(() => {
        const contentHeight = innerContentRef.current?.clientHeight + 80;
        setMaxHeight(
          windowHieght > contentHeight ? contentHeight : windowHieght
        );
      }, 100);
    }
    scrollToToday();
  }, [showContent]);

  // Check if content should be shown
  if (!showContent) {
    // If content should not be shown, return a Loading component
    return <Loading />;
  } else {
    // If content should be shown, render the main content
    return (
      <div>
        <div className="dashboard-table-wrapper">
          <Resizable
            defaultSize={{ height: Number(initialHeight), width: "100%" }}
            onResize={handleTableHeight}
            maxHeight={maxHeight}
            minHeight={100}
          >
            <div className="main-panel" ref={div1Ref} onScroll={handleScroll}>
              <div className="header-row">
                <TableHeader />
              </div>

              <div className="content-row test-content" ref={innerContentRef}>
                {!!data?.length &&
                  data.map((user) => (
                    <ContentRow key={user.userId} user={user} />
                  ))}
              </div>
            </div>
          </Resizable>

          {/* unassigned tasks */}
          <div className="unassigned-table-wrap">
            <h3>Unassigned Tasks</h3>
            <div
              className="unassigned-table"
              ref={div2Ref}
              onScroll={handleScroll}
            >
              <div className="content-row">
                {unassignedData &&
                  unassignedData.map((category) => (
                    <ContentRowUnassigned
                      key={category?.categoryId}
                      category={category}
                    />
                  ))}
              </div>
            </div>
          </div>

          <MainTaskView
            showContent={showContent}
            setShowContent={setShowContent}
          />
        </div>
      </div>
    );
  }
};

export default DashboardTable;
