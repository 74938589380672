import { PrimeReactProvider } from "primereact/api";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/AuthContext";
import AppRoutes from "./routes";
import "./assets/styles/index.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <AuthContextProvider>
        <AppRoutes />
      </AuthContextProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

reportWebVitals();
