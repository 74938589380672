import {
  addDays,
  endOfDay,
  startOfISOWeek,
  sub,
  subWeeks,
} from "date-fns";
import { useDataContext } from "../../../../hooks/useDataContext";
import YearChange from "./YearChange";
import WeekChange from "./WeekChange";
import MonthChange from "./MonthChange";

const DATE_OPTIONS = {
  THIS_WEEK: "this_week",
  NEXT_14_DAYS: "next_14_days",
  NEXT_30_DAYS: "next_30_days",
  LAST_WEEK: "last_week",
  LAST_14_DAYS: "last_14_days",
  LAST_30_DAYS: "last_30_days",
  YEAR_WEEK_NUMBER: "year_week_number",
  YEAR_MONTH_NUMBER: "year_month_number",
};
export default function AdvancedFilterOptions() {
  const { filters, dispatch } = useDataContext();
  const { selectedFilter } = filters;
  // open advance filter week
  const toggleWeekRangeSelector = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...filters,
        selectedFilter: DATE_OPTIONS.YEAR_WEEK_NUMBER,
      },
    });
  };

  // open advance filter Month
  const toggleMonthRangeSelector = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...filters,
        selectedFilter: DATE_OPTIONS.YEAR_MONTH_NUMBER,
      },
    });
  };
  const getStartOfWeek = (date) => {
    return addDays(date, 1 - date.getDay());
  };

  const getEndOfWeek = (date) => {
    return addDays(date, 7 - date.getDay());
  };
  
  const checkActiveFilter = (activeFilter) => {
    if (selectedFilter === activeFilter) {
      return "active";
    } else {
        return '';
    };
  };
  const handleDateRangeSelection = (selectedOption) => {
    const today = new Date(); // Define 'today' variable here
    const DAYS_IN_TWO_WEEKS = endOfDay(addDays(today, 13));
    const DAYS_IN_ONE_MONTH = endOfDay(addDays(today, 29));
    const setFilterPayload = (startDate, endDate) => {
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...filters,
          startDate,
          endDate,
          selectedFilter: selectedOption,
          getSelectedMonth: null,
          getSelectedYear: null,
          getSelectedWeekNumbers: null,
        },
      });
    };
    switch (selectedOption) {
      case DATE_OPTIONS.THIS_WEEK:
        const startOfWeek = new Date(getStartOfWeek(today));
        const endOfWeek = new Date(getEndOfWeek(today));
        setFilterPayload(startOfWeek, endOfWeek);
        break;
      case DATE_OPTIONS.NEXT_14_DAYS:
        setFilterPayload(today, DAYS_IN_TWO_WEEKS);
        break;
      case DATE_OPTIONS.NEXT_30_DAYS:
        setFilterPayload(today, DAYS_IN_ONE_MONTH);
        break;
      case DATE_OPTIONS.LAST_WEEK:
        setFilterPayload(
          getStartOfWeek(sub(new Date(), { weeks: 1 })),
          getEndOfWeek(sub(new Date(), { weeks: 1 }))
        );
        break;
      case DATE_OPTIONS.LAST_14_DAYS:
        setFilterPayload(
          sub(today, { days: 14 }),
          sub(new Date(), { days: 1 })
        );
        break;
      case DATE_OPTIONS.LAST_30_DAYS:
        setFilterPayload(
          sub(today, { days: 30 }),
          sub(new Date(), { days: 1 })
        );
        break;
      case "clear":
        setFilterPayload(
          startOfISOWeek(subWeeks(new Date(), 5)),
          addDays(new Date(), 90)
        );
        break;
      default:
    }
  };

  return (
    <>
      <ul>
        <li
          onClick={() => handleDateRangeSelection(DATE_OPTIONS.THIS_WEEK)}
          className={`${checkActiveFilter(DATE_OPTIONS.THIS_WEEK)}`}>
          <span>Denne uge</span>
        </li>
        <li
          onClick={() => handleDateRangeSelection(DATE_OPTIONS.NEXT_14_DAYS)}
          className={`${checkActiveFilter(DATE_OPTIONS.NEXT_14_DAYS)}`}>
          <span>Naeste 14 dage</span>
        </li>
        <li
          onClick={() => handleDateRangeSelection(DATE_OPTIONS.NEXT_30_DAYS)}
          className={`${checkActiveFilter(DATE_OPTIONS.NEXT_30_DAYS)}`}>
          <span>Naeste 30 dage</span>
        </li>
        <li
          onClick={() => handleDateRangeSelection(DATE_OPTIONS.LAST_WEEK)}
          className={`${checkActiveFilter(DATE_OPTIONS.LAST_WEEK)}`}>
          <span>Sidste uge</span>
        </li>
        <li
          onClick={() => handleDateRangeSelection(DATE_OPTIONS.LAST_14_DAYS)}
          className={`${checkActiveFilter(DATE_OPTIONS.LAST_14_DAY)}`}>
          <span>Sidste 14 dage</span>
        </li>
        <li
          onClick={() => handleDateRangeSelection(DATE_OPTIONS.LAST_30_DAYS)}
          className={`${checkActiveFilter(DATE_OPTIONS.LAST_30_DAYS)}`}>
          <span>Sidste 30 dage</span>
        </li>
        <li
          className={`click-trigger ${checkActiveFilter(
            DATE_OPTIONS.YEAR_WEEK_NUMBER
          )}`}
          onClick={toggleWeekRangeSelector}>
          <span>Uge nr.</span>
        </li>
        <li
          className={`click-trigger ${checkActiveFilter(
            DATE_OPTIONS.YEAR_MONTH_NUMBER
          )}`}
          onClick={toggleMonthRangeSelector}>
          <span>Brugertilpasset</span>
        </li>
      </ul>
      <div
        className={`date-range-selector week-selector ${checkActiveFilter(
          DATE_OPTIONS.YEAR_WEEK_NUMBER
        )}`}>
        <YearChange handleYearChangeFor={DATE_OPTIONS.YEAR_WEEK_NUMBER} />
        <WeekChange />
      </div>
      <div
        className={`date-range-selector month-selector ${checkActiveFilter(
          DATE_OPTIONS.YEAR_MONTH_NUMBER
        )}`}>
        <YearChange handleYearChangeFor={DATE_OPTIONS.YEAR_MONTH_NUMBER} />
        <MonthChange />
      </div>
    </>
  );
}
