import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { useDataContext } from '../../../../hooks/useDataContext';
// Create an array of month names
const monthOptions = Array.from({ length: 12 }, (_, index) => {
    const monthDate = new Date(2000, index, 1); // Use any arbitrary year, day doesn't matter
    return format(monthDate, "MMMM");
});


export default function MonthChange() {
    const { filters, dispatch } = useDataContext();

    const handleMonthChange = (event) => {
        const selectedMonthValue = event.target.value;
        const selectedYear = filters.getSelectedYear
        if (selectedYear !== undefined) {
            const yearAndMonth = `${selectedYear} ${selectedMonthValue}`;
            const yearAndMonthFormatted = yearAndMonth
                ? new Date(yearAndMonth)
                : null;
            dispatch({
                type: "SET_FILTERS",
                payload: {
                    ...filters,
                    startDate: startOfMonth(yearAndMonthFormatted),
                    endDate: endOfMonth(yearAndMonthFormatted),
                    getSelectedYear: selectedYear,
                    getSelectedMonth: selectedMonthValue,
                    selectedFilter: "year_month_number",
                    getSelectedWeekNumbers: null,
                },
            });
        }
    };
    return (
        <div className="date-col">
            <label>Vaelg Maned</label>
            <Dropdown
                value={filters.getSelectedMonth}
                options={monthOptions}
                onChange={handleMonthChange}
                emptyMessage={"select year"}
                // onBlur={sendFilterDate}
                placeholder="Month"
            />
        </div>
    )
}
