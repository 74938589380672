import React, { useEffect, useRef, useState } from "react";
import Arrow from "../../assets/images/down-arrow.svg";
import useLogout from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";
import "../../assets/styles/Header.scss";

const DropItems = ["Logout"];

const ProfileDropdown = () => {
  const [open, setOpen] = useState(false);
  const headerRef = useRef();
  const { logout } = useLogout();
  const { user } = useAuthContext();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (!headerRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="user-Login-info" ref={headerRef}>
      {/* dropdown header */}
      <div className="dropdown-header" onClick={() => setOpen(!open)}>
        <div className="user-avatar">
          {/* <img src={Avatar} alt="avatar" /> */}
          <span>{user.name.split("")[0]}</span>
        </div>
        <div className="user-details">
          <h5>{user.name}</h5>
          <p>{user.email}</p>
        </div>
        <i className="icon">
          <img
            src={Arrow}
            alt="arrow"
            className={open ? "menu-open" : "menu-closed"}
          />
        </i>
      </div>
      {/* dropdown content */}
      {open && (
        <div className="dropdown-panel" onClick={logout}>
          <ul>
            {DropItems.map((menu) => (
              <li onClick={() => setOpen(false)} key={menu}>
                <span>{menu}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
