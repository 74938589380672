import { subDays } from "date-fns";
import { useDataContext } from "../../../../hooks/useDataContext";
import Arrow from "../../../../assets/images/down-arrow.svg";

export default function PrevBtn({ daysDifference }) {
  const { filters, dispatch } = useDataContext();
  const { startDate, endDate } = filters;

  const handlePrev = () => {
    // Calculate the start date by setting the time to the beginning of the day
    const prevStartDate = subDays(startDate, daysDifference);
    // Calculate the end date by setting the time to the end of the day
    const prevEndDate = subDays(endDate, daysDifference);
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...filters,
        startDate: new Date(prevStartDate),
        endDate: new Date(prevEndDate),
      },
    });
  };

  return (
    <div className="prev-arrow" onClick={handlePrev}>
      <img src={Arrow} alt="arrow" />
    </div>
  );
}
