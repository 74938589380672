import {
  addWeeks,
  endOfISOWeek,
  getISOWeeksInYear,
  startOfISOWeek,
} from "date-fns";
import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../../hooks/useDataContext";
import { Dropdown } from "primereact/dropdown";

export default function WeekChange() {
  const { filters, dispatch } = useDataContext();
  const [weekNumbers, setWeekNumbers] = useState([]);
  const selectedYear = filters.getSelectedYear;

  useEffect(() => {
    if (selectedYear) {
      addWeeKnummber();
    }
  }, [selectedYear]);

  const handleWeekNumber = (event) => {
    const getWeekNumber = event.target.value;
    if (selectedYear !== undefined) {
      // Create a date for the first day of the year
      const startDate = new Date(selectedYear, 0, 1);
      // Calculate the start date of the provided week
      const startOfWeekDate = startOfISOWeek(
        addWeeks(startDate, getWeekNumber - 1)
      );
      // Calculate the end date of the provided week
      const endOfWeekDate = endOfISOWeek(startOfWeekDate);
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...filters,
          startDate: startOfWeekDate,
          endDate: endOfWeekDate,
          getSelectedYear: selectedYear,
          getSelectedWeekNumbers: getWeekNumber,
          getSelectedMonth: null,
          selectedFilter: "year_week_number",
        },
      });
    }
  };

  const addWeeKnummber = () => {
    const weeksInYear = getISOWeeksInYear(new Date(selectedYear, 0, 1));
    let weekNumberarray = [];
    for (let i = 1; i <= weeksInYear; i++) {
      weekNumberarray.push(i);
    }
    setWeekNumbers(weekNumberarray);
  };
  return (
    <div className="date-col">
      <label>Vaelg Udfyld ugenummerar</label>
      <Dropdown
        value={filters.getSelectedWeekNumbers}
        options={weekNumbers}
        onChange={handleWeekNumber}
        emptyMessage={"select year"}
        placeholder="Week"
      />
    </div>
  );
}
