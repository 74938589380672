import "../../assets/styles/Loader.css"
import React from "react";

const Loading =()=>{

    return (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      );
};


export default Loading;