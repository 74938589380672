import React from "react";
import { toast } from "react-toastify";

const showToast = (type, message, options, id = 0, onOk, onCancel) => {
  switch (type) {
    case "Success":
      toast.success(message, options);
      break;
    case "Error":
      toast.error(message, options);
      break;
    case "Loading":
      return toast.loading(message, options);
    case "Update":
      toast.update(id, {
        render: message,
        type: options.type,
        isLoading: false,
      });
      break;
    case "ok":
      toast.warning(
        <CustomToast message={message} onOk={onOk} onCancel={onCancel} />,
        options
      );
      break;
    default:
      break;
  }
};

const Toast = () => {
  return null;
};

export { showToast };
export default Toast;

const CustomToast = ({ message, onOk, onCancel }) => {
  return (
    <div>
      <div>{message}</div>
      <button onClick={onOk}>Ok</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
};
